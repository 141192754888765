
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

iframe {
  pointer-events: none;
}

@media screen and (min-width: 600px) {  
  .App {
    display: flex;
    justify-content: center;
    background-color: #f9f9f9;
  }

  .Mainapp {
    width: 600px;
    margin: 0 auto;
    position: relative;
    background-color: white;
    height: 100vh;
  }

  .header {
    width: 100%;
    height: 60px;
    box-shadow: 0 5px 30px 0 rgb(194 198 208 / 50%);
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
  }

  .input {
    padding: 12px 16px 12px 16px;
    background: #f7f9fc;
    border-radius: 12px;
  }

  .onboarding_content {
    padding-left: 40px;
  }

  #mobile:focus {
    outline: none;
    border: none;
  }

  #mobile {
    padding: 12px 16px 12px 16px;
    background: #f7f9fc;
    border-radius: 12px;
    outline: none;
    border: none;
  }

  #mobilelabel {
    display: none;
  }

  #mobilelabel_2 {
    display: flex;
    justify-content: center;
  }

  .otpsubtitle {
    padding-left: 20px;
  }

  .otpotion {
    padding-right: 20px;
  }

  #otp1 {
    width: 37px;
    height: 42px;
    margin-left: 15px;
    border-radius: 5px;
    border: 1px solid #eeeeee;
    background-color: #eeeeee;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #e41e26;
  }

  #otp2 {
    width: 37px;
    height: 42px;
    margin-left: 15px;
    border-radius: 5px;
    border: 1px solid #eeeeee;
    background-color: #eeeeee;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.05;
    letter-spacing: normal;
    text-align: center;
    color: #e41e26;
  }

  #otp3 {
    width: 37px;
    height: 42px;
    margin-left: 15px;
    border-radius: 5px;
    border: 1px solid #eeeeee;
    background-color: #eeeeee;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.05;
    letter-spacing: normal;
    text-align: center;
    color: #e41e26;
  }

  #otp4 {
    width: 37px;
    height: 42px;
    margin-left: 15px;
    border-radius: 5px;
    border: 1px solid #eeeeee;
    background-color: #eeeeee;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.05;
    letter-spacing: normal;
    text-align: center;
    color: #e41e26;
  }

  .otp_input:focus {
    border: none;
    outline: none;
  }

  .personal_input {
    width: 100%;
    height: 41px;
    font-weight: 500;
    border-radius: 5px;
    border: solid 1px #d4d4d4;
    background-color: var(--white);
  }

  .back-icon {
    width: 30px;
    margin-right: 10px;
  }

  /* .page-title {
        margin-top: 5%;
    } */
  #mobview {
    display: none;
  }

  #webview {
    display: flex;
    justify-content: center;
  }

  select,
  input[type="text"] {
    width: 100%;
    padding-top: 12px;
    background: transparent;
    border: 0;
    font-size: 16px;
  }

  select,
  input[type="date"] {
    width: 100%;
    padding-top: 12px;
    background: transparent;
    border: 0;
    font-size: 16px;
  }

  input[type="text"]:focus {
    outline: none;
    border: none;
  }

  .header-area {
    height: 40px;
    overflow: hidden;
    display: block;
    display: flex;
    align-items: center;
    z-index: 1;
    width: 100%;
    padding: 7px 0px 7px 0px;
    background-color: #ffffff;
    position: fixed;
    top: 0;
    z-index: 2;
    width: 100%;
  }

  .checkbox_input_family {
    width: 100%;
    height: 180px;
    box-shadow: 0 3px 6px 0 rgba(172, 172, 172, 0.23);
    background-color: #fffafa;
  }

  .page-content {
    width: 89%;
    height: 45px;
    margin: 0px auto 30px auto;
    /* font-family: SFProText; */
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }

  .page-title {
    /* margin: 80px 10px 20px 20px; */
    /* font-family: SFProText; */
    font-size: 18px;
    padding: 20px;
    padding-top: 10px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }

  .assets_input {
    padding: 10px;
    display: flex;
    justify-content: center;
    box-shadow: 0 3px 6px 0 rgb(172 172 172 / 34%);
    background-color: var(--white);
  }

  .motor_input {
    border-top: unset;
    border-bottom: unset;
    border-left: unset;
    padding: 5px;
    font-size: 15px;
    width: 75%;
    margin-left: 10px;
    border-right: unset;
    border-bottom: 1px solid #e41e26;
  }

  .minus,
  .plus {
    padding: 10px;
    margin-right: 10px;
    font-size: 4vw;
    display: inline-block;
    font-weight: 500;
    text-align: right;
    float: right;
    /* color: #e4e4e4; */
    color: #e41e26;
    cursor: pointer;
  }

  .checkbox_title {
    object-fit: contain;
    opacity: 0.81;
    font-size: 12px;
    font-weight: bolder;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    padding: unset;
    margin: unset;
    margin-left: 10px;
  }

  .insurance_details_webview {
    margin-left: 20px;
  }

  .content-insurance-details {
    margin-left: 20px;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
  }

  .insurance_details_icon {
    padding-top: 85px;
  }

  .insurance_checking {
    text-align: center;
  }

  .kompass_logo img {
    width: 130px;
    height: auto;
  }

  .insurance_btn {
    width: 40%;
    border-radius: 2px;
    background-color: #e41e26;
    color: white;
    font-weight: 500;
    font-size: 15px;
    padding: 10px;
    border: unset;
    cursor: pointer;
    margin-top: 20px;
  }

  .insurance_button {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid lightgrey;
    background-color: white;
    font-size: 15px;
    font-weight: 500;
    padding: 10px;
    cursor: pointer;
    margin-top: 20px;
  }

  .health-input {
    margin: 0px auto 0px auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .health-svg-family {
    height: 120px;
    margin-top: 25px;
  }

  .health-svg {
    height: 70px;
    margin-top: 10px;
  }

  .assets-icon {
    height: 50px;
  }

  .assets-checkbox {
    height: 50px;
    margin-right: 10px;
  }

  .pb_unbset {
    padding-bottom:unset !important;
  }

  .vehicle-name {
    opacity: 0.77;
    /* font-family: SFProText; */
    color: #000000;
    font-size: 15px;
  }

  /* The container */
  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 20px;
    left: 20px;
    height: 15px;
    width: 15px;
    background-color: #ffffff;
    border: 1px solid #707070;
    margin-right: 10px;
    border-radius: 4px;
  }

  /* When the checkbox is checked, add a green background */
  .container input:checked~.checkmark {
    background-color: #92bf61;
    border: 1px solid #92bf61;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked~.checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 4px;
    top: 0px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .min_amt {
    margin-top: 5px;
    opacity: 0.6;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.08;
    letter-spacing: normal;
    float: left;
    color: #000000;
    /* font-family: SFProText; */
  }

  .max_amt {
    margin-top: 5px;
    opacity: 0.6;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.08;
    letter-spacing: normal;
    float: right;
    color: #000000;
    /* font-family: SFProText; */
  }

  .Familydetails_maincomponent {
    width: 100%;
    /* padding: 0 0 28px; */
    background-color: #ffffff;
  }

  .Familydetails_maincomponent_family {
    width: 100%;
    padding-top: 60px;
    /* padding: 0 0 28px; */
    background-color: white;
  }

  .insurance_category {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    margin: 20px;
    margin-bottom: unset;
    padding: 19.5px 0px 15px 0px;
    border-radius: 5px;
    /* border: solid 1px #dddddd; */
    background-color: #fffafa;
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 11%);
  }

  .insurance_category div {
    display: flex;
    align-items: center;
  }

  .insurance_cat_headernone {
    width: 100%;
    margin: 2.5px 178px 7px 15px;
    opacity: 0.81;
    /* font-family: SFProText; */
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }

  .input-box {
    box-sizing: border-box;
    z-index: 2;
    width: 100%;
    height: 285px;
    margin: -100px auto 70px auto;
    padding: 11px 15px 34px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 16%);
    background-color: white;
  }

  .insurance_cat_icon {
    width: 24px;
    height: 24px;
    margin: 0.5px 20px 10px 178px;
    object-fit: contain;
    opacity: 0.14;
  }

  .slide_top {
    position: relative;
    right: -100%;
    -webkit-animation: slide 0.5s forwards;
    -webkit-animation-delay: 0.5s;
    animation: slide 0.5s forwards;
    animation-delay: 0.5s;
  }

  .credit_score_container {
    display: flex;
    align-items: center;
    background-color: #f2f2f2;
    padding: 20px 20px 20px 20px;
    margin-bottom: 20px;
  }

  .credit_score {
    width: 32px;
    height: 29px;
    /* font-family: SFProText; */
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #e41e26;
    margin: 15px;
    padding: 15px;
    border: solid 6px #f2f2f2;
    background-color: white;
    border-radius: 100px;
  }

  /*Rahul */
  h5 {
    margin: unset;
    padding: unset;
  }

  h4 {
    margin: unset;
    padding: unset;
  }

  h3 {
    margin: unset;
    padding: unset;
  }

  .warning_title {
    padding: 12px 10px 10px 20px;
    font-size: 12px;
    color: grey;
  }

  .onbording_content {
    padding: 0px 2px 20px 10px;
  }

  .content-insurance-details {
    margin-left: 20px;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
  }

  .content {
    margin: 20px;
    /* font-family: SFProText; */
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    color: #acacac;
  }

  .btn {
    width: 40%;
    border-radius: 5px;
    background-color: #e41e26;
    font-size: 14px;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    /* padding: 18px 26px; */
    margin-top: 10px;
    padding: 10px;
    border: 0;
    z-index: 1;
    font-family: "Poppins";
    cursor: pointer;
  }

  .nav_header {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    margin: 0 0 1px;
    padding: 13px 196px 13px 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.14);
    background-color: #ffffff;
  }

  .family_nav {
    width: 200px;
    height: 19px;
    margin: 3px 0 2px 15px;
    opacity: 0.71;
    /* font-family: SFProText; */
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }

  .checkbox_input {
    margin-bottom: 18px;
    width: 32%;
    height: 115px;
    box-shadow: 0 3px 6px 0 rgb(172 172 172 / 23%);
    background-color: #ffc0cb12;
  }

  .checkbox_input_home {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
  }

  .family_checkbox {
    width: 18px;
    height: 18px;
    cursor: pointer;
    border-radius: 4px;
    border: solid 1px #707070;
    background-color: #ffffff;
  }

  .assets_checkbox {
    width: 18px;
    height: 18px;
    border-radius: 4px;
    border: solid 1px #707070;
    background-color: #ffffff;
  }

  .assets-svg {
    margin: 15px 0px 0px -23px;
  }

  span {
    cursor: pointer;
  }

  .number {
    margin: 5px;
    padding: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 50%;
  }

  .asset_input_subcontainer {
    width: 35%;
  }

  .minus,
  .plus {
    padding: 10px;
    margin-right: 10px;
    font-size: 14px;
    display: inline-block;
    font-weight: 500;
    text-align: right;
    float: right;
    /* color: #e4e4e4; */
    color: #e41e26;
  }

  .plusMinusInput {
    height: 21px;
    width: 35%;
    text-align: center;
    font-size: 15px;
    border: 1px solid #ddd;
    display: inline-block;
    vertical-align: middle;
  }

  .motor_input {
    border-top: unset;
    border-bottom: unset;
    border-left: unset;
    padding: 5px;
    font-size: 15px;
    width: 75%;
    margin-left: 10px;
    border-right: unset;
    border-bottom: 1px solid #e41e26;
  }

  .motor_input:focus {
    outline: none;
  }

  .checkbox_title {
    object-fit: contain;
    opacity: 0.81;
    font-size: 14px;
    cursor: pointer;
    font-weight: bolder;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    padding: unset;
    margin: unset;
    margin-left: 10px;
  }

  .checkbox_subtitle {
    width: 300px;

    /* font-family: SFProText; */
    font-size: 16px;
    /* padding-bottom: 10px; */
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.69;
    letter-spacing: normal;
    text-align: left;
    color: #00000031;
  }

  .family_input {
    border-bottom: solid 1px #dddddd;
    border-top: unset;
    border-right: unset;
    border-left: unset;
    width: 302px;
    padding: 15px;
    margin-left: 18px;
    width: 85%;
    transition: all 0.5s ease;
  }

  .column-card-premium {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .familydate_input {
    width: 85%;
    height: 20px;
    /* margin: 0 7px 0 0; */
    font-size: 20px;
    font-weight: 500;
    padding: 9px 10px 9px 11px;
    outline: none;
    border: none;
    border-bottom: solid 2px #e41e26;
    background-color: transparent;
  }

  .familydate_input::-webkit-input-placeholder {
    color: lightgrey;
  }

  .familydate_input::placeholder {
    color: lightgrey;
  }

  .family_input:focus {
    outline: none;
  }

  /* .insurance_category {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 20px;
    margin: 30px 20px 20px;
    padding: 19.5px 0px 15px 0px;
    border-radius: 5px;
    border: solid 1px #dddddd;
    background-color: #ffffff;
  } */
  .insurance_category div {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .insurance_cat_header {
    width: 100px;
    margin: 0px 25px 7px 15px;
    opacity: 0.81;
    /* font-family: SFProText; */
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }

  .insurance_cat_headernone {
    width: 100%;
    height: 30px;
    margin: 30px 20px 20px;
    opacity: 0.81;
    /* font-family: SFProText; */
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }

  .insurance_cat_icon {
    width: 24px;
    height: 24px;
    margin: 0.5px 20px 10px 178px;
    object-fit: contain;
    opacity: 0.14;
  }

  .credit_score_container {
    display: flex;
    align-items: center;
    height: 140px;
    background-color: #f2f2f2;
    padding: 20px 20px 20px 20px;
    margin-top: 18%;
    background-color: #ecf5e3;
  }

  .credit_score {
    width: 32px;
    height: 29px;
    /* font-family: SFProText; */
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #e41e26;
    margin: 15px;
    padding: 15px;
    border: solid 6px #f2f2f2;
    background-color: white;
    border-radius: 100px;
  }

  .credit-score-title {
    width: 193px;
    object-fit: contain;
    opacity: 0.77;
    /* font-family: SFProText; */
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }

  .credit-score-number {
    margin: 15px 0px 15px;
  }

  .super_top_up_main_container {
    width: 100%;
    height: auto;
    background-color: #ffffff;
  }

  .category_insurance_header {
    height: 43px;
    margin: 10px 33px 30px 27px;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }

  .progress {
    width: 10px;
    height: 1px;
  }

  .personal_input2 {
    width: 85%;
    height: 50px;
    margin: 7px 20px 20px;
    font-size: 17px;
    padding-left: 10px;
    font-weight: 500;
    border-radius: 5px;
    border: solid 1px #d4d4d4;
    background-color: var(--white);
    display: flex;
    align-items: center;
  }

  .Family_animation {
    position: absolute;
    width: 100%;
  }

  .personal_input2 input {
    border: none;
    outline: none;
    margin: unset;
    padding: unset;
    width: 96%;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
  }

  .progress_container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .suminsured_container {
    width: 90%;
    height: 235px;
    padding: 1px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    background-color: var(--white);
  }

  .slider {
    width: 90%;
    margin-top: 35px;
  }

  .rangeinput {
    color: lightgrey;
    font-size: 15px;
    padding-right: 10px;
  }

  .header_labels {
    width: 216px;
    height: 21px;
    margin: 20px;
    object-fit: contain;
    /* font-family: SFProText; */
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }

  .other_checkbox_input {
    display: flex;
    align-items: center;
  }

  .other_family_input {
    border-bottom: solid 1px #dddddd;
    border-top: unset;
    border-right: unset;
    border-left: unset;
    width: 70%;
    text-align: center;
    padding: 8px;
    font-size: 25px;
    font-weight: 500;
    background: white;
  }

  .bottom_btn_container {
    display: flex;
    justify-content: flex-end;
    margin: 20px;
  }

  .Recommendation_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 45px;
    overflow: hidden;
    padding: 10px;
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 14%);
    background-color: #ffffff;
    z-index: 2;
  }

  .recommendation_header {
    padding-left: 10px;
  }

  .mobile_text_field {
    border-bottom: solid 1px #dddddd;
    border-top: unset;
    border-right: unset;
    border-left: unset;
    width: 87%;
    padding: 8px;
    padding-left: unset;
    font-size: 16px;
    font-weight: 500;
  }

  #radio {
    padding-right: 35px;
    padding-left: 35px;
  }

  .mobile_text_field:focus {
    outline: none;
  }

  .other_family_input:focus {
    outline: none;
  }

  .income_amount_container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

  .insurance_checking {
    padding: 15px;
    font-weight: 400;

    text-align: center;
    font-size: 25px;
    text-align: center;
  }

  .otpsubtitle2 {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.93;
    letter-spacing: normal;
    text-align: center;
    color: #8d8d8d;
  }

  .otpsubtitle2 label {
    font-size: 16px;
    padding-left: 10px;
    font-weight: 500;
    color: #e41e26;
  }

  .recommendation_card {
    width: 100%;
    /* margin: 15px; */
    padding: 8px;
    border-radius: 5px;
    /* background-image: linear-gradient(59deg, #ffffff, #fbb03b, #e41e26); */
    background-image: linear-gradient(to bottom, #f1ffe2, #fafafa);
    background: #f1ffe2;
    box-shadow: 0 3px 6px 0 rgba(212, 212, 212, 0.5);
  }

  .checkbox_input_city_container {
    margin: 5px 22px 0;
    padding: 0px 26px 0px 22px;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: white;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0, 1, 0, 1);
  }

  .checkbox_input_city_container.show {
    padding: 0px 26px 0px 22px;
    border-radius: 0px 0px 5px 5px;
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
    background-color: white;
    height: 300px;
    max-height: 9999px;
    overflow: scroll;
    transition: all 0.5s cubic-bezier(1, 0, 1, 0);
    margin: 20px;
    margin-top: -2px;
  }

  .home_input_opt {
    font-size: 15px;
    display: flex;
    font-weight: 500;
    /* margin: 5px; */
    /* padding: 5px 20px 10px; */
    padding-left: 11px;
  }

  .home_value_input {
    font-weight: bold;
    padding-top: unset;
    padding-right: unset;
    padding-bottom: unset;
    padding-left: 10px;
    margin: unset;
    font-size: 16px;
    display: flex;
  }

  .circle {
    position: relative;
    width: 50%;
    padding-bottom: 50%;
    background: gold;
    border-radius: 100%;
  }

  .circle h3 {
    position: absolute;
    top: 45%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    margin: 0;
    font-size: 35px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.9;
    letter-spacing: normal;
    text-align: center;
    color: white;
  }

  .sideCircle {
    background: #febc4e;
    width: 25px;
    height: 25px;
    border-radius: 100px;
    position: relative;
    top: -80px;
    left: 148px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  }

  .sideCircle2 {
    background: #febc4e;
    width: 20px;
    height: 20px;
    border-radius: 100px;
    position: relative;
    top: -120px;
    left: 320px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  }

  .sideCircle3 {
    background: #4a90e2;
    width: 20px;
    height: 20px;
    border-radius: 100px;
    position: relative;
    top: -95px;
    left: 295px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  }

  .circle p {
    position: absolute;
    top: 65%;
    left: 50%;
    width: 100%;
    font-size: 12px;
    color: white;
    text-align: center;
    -webkit-transform: translate(-50%, -65%);
            transform: translate(-50%, -65%);
    margin-top: 15px;
  }

  .card {
    position: relative;
    overflow: hidden;
    left: 0;
    background: #ffffff;
    right: 0;
    padding: 4px;
    padding-bottom: unset;
    border: 0.5px solid #ededed;
    border-radius: 5px 5px 0px 0px;
    margin: 10px 0px 0px;
  }

  .view-details {
    text-align: center;
    border-bottom: solid 1px #ededed;
    border-radius: 0px 0px 5px 5px;
    border-right: solid 1px #ededed;
    border-left: solid 1px #ededed;
    padding: 14px;
    font-size: 16px;
    color: #e41e26;
    cursor: pointer;
  }

  .view-details-content {
    max-height: 0;
    overflow: hidden;
    transition: all 1s cubic-bezier(0, 1, 0, 1);
  }

  .view-details-content.show {
    height: auto;
    max-height: 9999px;
    overflow: visible;
    transition: all 1s cubic-bezier(1, 0, 1, 0);
    display: flex;
    justify-content: space-between;
    text-align: left;
  }

  .recommend-card-title {
    margin-left: 10px;
    margin-bottom: 5px;
    /* font-family: SFProText; */
    font-size: 13px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    color: black;
  }

  .recommend-card-amt {
    margin: 0 0px 0px 15px;
    /* font-family: SFProText; */
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    color: #e41e26;
  }

  .recommend-card-amt-black {
    margin: 0 0px 0px 15px;
    /* font-family: SFProText; */
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    padding-right: 10px;
    color: #64a819;
  }

  .recommend-card-subtitle {
    margin-left: 10px;
    display: flex;
    align-items: center;
    /* font-family: SFProText; */
    color: #000000;
    opacity: 0.54;
    font-size: 15px;
  }

  .recommend-product-title {
    margin-left: 12px;
    float: left;
    /* font-family: SFProText; */
    color: #000000;
    opacity: 0.54;
    font-size: 12px;
    font-weight: normal;
  }

  .recommend-product {
    margin-left: 15px;
    float: left;
    /* font-family: SFProText; */
    color: #000000;
    font-size: 14px;
    font-weight: 600;
  }

  .subtitle-span {
    border: 0.5px solid gray;
    background: white;
    margin-right: 5px;
  }

  .column-card-1 {
    float: left;
    width: 15%;
    padding: 15px 0px 15px;
  }

  .column-card-2 {
    display: flex;
    padding: 12px 20px 23px 27px;
  }

  .column-card-3 {
    /* float: left; */
    width: 100%;
  }

  .column-card-4 {
    /* float: left; */
    width: 50%;
  }

  .column-card-cover {
    float: left;
    background-color: #f5f5f5;
    padding: 10px 0 10px 0;
  }

  .column-card-cover-left {
    /* border-right: 5px solid #ffffff;
        width: 48%; */
    width: 49%;
    margin-right: 5px;
  }

  .column-card-cover-right {
    /* border-left: 5px solid #ffffff;
        width: 48%; */
    width: 49%;
  }

  .row-card:after {
    content: "";
    display: table;
    clear: both;
  }

  .row-card {
    background-color: #fef3f4 !important;
  }

  .row-card-1 {
    margin: 5px 0px;
    margin-bottom: unset;
    background-color: #ffffff !important;
    display: flex;
    justify-content: center;
  }

  .row-card-2 {
    margin: 10px 10px;
    background-color: #ffffff !important;
    display: flex;
    justify-content: space-between;
  }

  .recommend-card-data {
    font-size: 14px;
    color: black;
    text-align: left;
    font-weight: 600;
  }

  .recommend-card-gst {
    font-size: 22px;
    font-weight: 600;
    color: #64a819;
    opacity: 0.81;
    /* font-family: SFProText; */
  }

  .recommendation_options_list {
    margin-top: 160px;
  }

  .cover-text-premium {
    opacity: 0.44;
    /* font-family: SFProText; */
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.69;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }

  .city_search {
    border: none;
    outline: none;
    padding: 15px;
    width: 91%;
    margin-top: 15px;
    font-size: 16px;
    border-radius: 8px;
    font-weight: 500;
    background-color: rgba(198, 198, 198, 0.23);
  }

  .tab-btn {
    width: 33.3%;
    padding: 15px;
    background-color: #ffffff;
    color: black;
    cursor: pointer;
    border: none;
    outline: none;
    z-index: 1;
    border-radius: 5px;
  }

  .tab-btn-focus {
    width: 33.3%;
    padding: 15px;
    outline: none;
    border: none;
    border-radius: 5px;
    background-color: rgba(248, 234, 234, 1);
    color: #e41e26;
  }

  .tab-btn-focus:focus {
    border: none;
    outline: none;
  }

  .city_search:focus {
    outline: none;
    border: none;
  }

  .cover-text {
    opacity: 0.44;
    /* font-family: SFProText; */
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.69;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    padding: unset;
    margin: unset;
  }

  .rcard_name {
    color: black;
    padding-bottom: 7px;
    text-align: left;
  }

  .rcard_content {
    color: black;
    font-weight: 700;
    font-weight: lighter;
    opacity: 0.6;
    font-size: 14px;
    text-align: left;
  }

  .assets-svg {
    display: flex;
    justify-content: center;
  }

  .item1 {
    grid-area: svg;
    height: 140px;
    text-align: left;
    margin-top: -15px;
    margin-left: -25px;
  }

  .item2 {
    grid-area: score;
    text-align: center;
    height: 140px;
    margin-right: 28px;
  }

  .grid-container {
    display: grid;
    grid-template-areas:
      "svg score score"
      "svg score score";
  }

  .card_creditscroe {
    /* width: 52px; */
    margin: 12px 4px 4px 0;
    /* font-family: SFProText; */
    font-size: 40px;
    font-weight: bolder;
    line-height: 0.7;
    letter-spacing: 0.4px;
    /* text-align: left; */
    color: black;
    z-index: 2;
    text-align: center;

    opacity: 0.6;
  }

  .score_status {
    width: 72%;
    margin: -35px -4px 0px 123px;
    /* font-family: SFProText; */
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: black;
  }

  .card_suggestion {
    /* font-family: SFProText; */
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #64a819;
    text-decoration: underline;
    padding-bottom: unset;
    margin-bottom: unset;
  }

  .recommendations_heading {
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    font-size: 16px;
    font-weight: 700;
  }

  .insurance_status {
    display: flex;
    justify-content: center;
    color: #e41e26;
    font-size: 12px;
  }

  .recommendation-car-svg {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .recommendation-card-svg {
    z-index: 1;
    position: relative;
    width: 40px;
  }

  .skeleton-h99tl35omha:empty {
    box-sizing: content-box;
    height: 30px;
    width: 120px;
    height: 30px;
    background-color: whitesmoke;
    border-radius: 5px 5px 5px 5px;
    background-image: linear-gradient(100deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 80%),
      linear-gradient(#f2f0f0 30px, transparent 0);
    background-repeat: repeat-y;
    background-size: 50px 170px, 100px 170px;
    background-position: 0 0, 70px 15px;
    -webkit-animation: shineForSkeleton-h99tl35omha 2s infinite;
            animation: shineForSkeleton-h99tl35omha 2s infinite;
  }

  @-webkit-keyframes shineForSkeleton-h99tl35omha {
    to {
      background-position: 100% 0, 70px 15px;
    }
  }

  @keyframes shineForSkeleton-h99tl35omha {
    to {
      background-position: 100% 0, 70px 15px;
    }
  }

  .suggested_category {
    width: 100px;
    height: 20px;
    color: white;
    padding: 14px 34.7px 15px 33px;
    border-radius: 2px;
    background-color: white;
  }

  .suggested_category p {
    padding: unset;
    margin: unset;
    color: black;
  }

  .recommendation_options {
    width: 100%;
    /* height: 45px; */
    margin: 30px 1px 20px;
    /* padding-left: 10px; */
    border-radius: 5px;
    display: flex;
    align-items: center;
    background-color: #ffffff;
  }

  .recommendation_options span {
    width: 100%;
    height: 16px;
    margin: 4px 0 4px 12px;
    opacity: 0.81;
    /* font-family: SFProText; */
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }

  .otp_input_container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    -webkit-animation: ease-in otp_render_reverse 0.5s;
            animation: ease-in otp_render_reverse 0.5s;
  }

  .company_info {
    height: 70px;
    margin: 30px 20px 10px;
    opacity: 0.54;
    /* font-family: SFProText; */
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }

  .otp_verification {
    width: 335px;
    height: 36px;
    margin: 1px 0.5px 10px 0;
    padding: 20px;
    opacity: 0.81;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }

  .pop4 {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    background-color: rgba(0, 0, 0, 0.65);
    bottom: 0;
    flex-direction: column;
    overflow-y: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
  }

  .pop3 {
    width: 100%;
    display: -webkit-flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
  }

  .pop2 {
    background-color: rgba(255, 255, 255, 1);
    background-color: rgba(var(--f23, 255, 255, 255), 1);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    max-height: 100%;
    overflow: hidden;
    display: -webkit-flex;
    flex-direction: column;
  }

  .pop1 {
    justify-content: center;
    display: -webkit-flex;
    flex-direction: column;
    background-color: white;
  }

  .popupicon1 {
    font-size: 50px;
    color: #075e54;
    cursor: pointer;
  }

  .popupicon2 {
    font-size: 50px;
    color: red;
    cursor: pointer;
  }

  .otpverification {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid lightgrey;
    width: 335px;
    padding: 5px;
  }

  .tab-btn {
    width: 33.3%;
  }

  .tab-btn:focus {
    width: 33.3%;
    outline: none;
    background-color: rgba(248, 234, 234, 1);
    color: #e41e26;
  }

  #sc {
    width: 40px;
    height: 40px;
    border-radius: 100px;
    background: linear-gradient(90deg, #ededed, #ffffff, #ededed);
    -webkit-animation-name: load;
            animation-name: load;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-direction: forwards;
            animation-direction: forwards;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
  }

  #city_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15;
    padding-left: unset;
    background-color: white;
  }

  #sc2 {
    width: 80%;
    padding: 3px;
    border-radius: 5px;
    margin: 5px;
    background: linear-gradient(90deg, #ededed, #ffffff, #ededed);
    -webkit-animation-name: load;
            animation-name: load;
    -webkit-animation-duration: 1.5ms;
            animation-duration: 1.5ms;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-direction: forwards;
            animation-direction: forwards;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
  }

  #sc1 {
    width: 40%;
    height: 7px;
    border-radius: 5px;
    margin: 5px;
    background: linear-gradient(90deg, #ededed, #ffffff, #ededed);
    -webkit-animation-name: load;
            animation-name: load;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-direction: forwards;
            animation-direction: forwards;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
  }

  .fade-in {
    -webkit-animation: fadeIn 1s;
            animation: fadeIn 1s;
  }

  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
      transition: opacity 250ms ease-in;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transition: opacity 250ms ease-in;
    }

    to {
      opacity: 1;
    }
  }

  /* #slide {
    position: relative;
    right: -100%;
    -webkit-animation: slide 0.5s forwards;
    -webkit-animation-delay: 0.5s;
    animation: slide 0.5s forwards;
    animation-delay: 0.5s;
  }
  #slide {
    position: relative;
    right: -100%;
    -webkit-animation: slide 0.5s forwards;
    -webkit-animation-delay: 0.5s;
    animation: slide 0.5s forwards;
    animation-delay: 0.5s;
  } */

  .circular-chart {
    display: block;
    margin: 0px auto;
    max-width: 80%;
    max-height: 250px;
  }

  .circle-bg {
    fill: none;
    stroke: black;
    opacity: 0.05;
    stroke-width: 3.8;
  }

  .circle {
    fill: none;
    stroke-width: 2.8;
    stroke-linecap: round;
    -webkit-animation: progress 1s ease-out forwards;
            animation: progress 1s ease-out forwards;
  }

  @-webkit-keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }

  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }

  .circular-chart.green .circle {
    stroke: #92bf61;
  }

  .percentage {
    fill: #92bf61;
    font-size: 14px;
    font-weight: 600;
    text-anchor: middle;
  }

  .onboarding_sub_content {
    width: 300px;
    margin: 5.7px 51px 5px 24px;
    object-fit: contain;
    font-size: 23px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }

  .genderDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 40px 20px 20px;
  }

  .multiple_input {
    font-weight: bold;
    padding: unset;
    margin: unset;
    font-size: 16px;
  }

  .onboarding_next-btn {
    width: 40%;
    height: 45px;
    border-radius: 2px;
    background-color: #e41e26;
    color: white;
    font-weight: 600;
    font-size: 16px;
    padding: 10px;
    margin-left: 20px;
    margin-top: 10px;
    border: unset;
  }

  .existingInsurance_pages {
    display: flex;
    justify-content: center;
    margin-top: 60px;
  }

  .personal_details_madatory_fileds {
    display: flex;
    justify-content: center;
  }

  .familydetails_content {
    margin: 20px;
    object-fit: contain;
    opacity: 0.77;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.09;
    letter-spacing: normal;
    color: #000000;
  }

  .input_display_2 {
    font-size: 16px;
    padding: 10px;
    color: "grey";
  }

  .family_input {
    width: 97%;
    margin: 6px auto 22px auto;
    padding: 15px;
    background-color: var(--white);
    transition: all 0.5s ease;
  }

  .single_input {
    font-weight: bold;
    padding: unset;
    margin: unset;
    font-size: 16px;
    padding-left: 10px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .otpsubtitle {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    color: #747474;
    padding-left: 20px;
  }

  .checkbox_input_family_display {
    width: 100%;
    height: auto;
    min-height: 180px;
    box-shadow: 0 3px 6px 0 rgb(172 172 172 / 23%);
    background-color: white;
  }

  .input_display {
    font-size: 18px;
    padding: 10px 10px 20px 10px;
    text-align: left;
  }

  .assets_input_right {
    padding: 10px;
    display: flex;
    justify-content: center;
    margin: 10px;
    box-shadow: 0 3px 6px 0 rgb(172 172 172 / 34%);
    background-color: var(--white);
  }

  input[type="range"] {
    -webkit-appearance: none;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    width: 300px;
    height: 5px;
    background: #e41e26;
    border: none;
    border-radius: 3px;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 0.5px solid gray;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #ffffff;
    margin-top: -7px;
  }

  input[type="range"]:focus {
    outline: none;
  }

  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #ccc;
  }

  .​checkbox_input_insurance {
    display: flex;
    align-items: center;
    padding-bottom: 7%;
  }

  .checkbox_input_insurance_container {
    margin: 5px 22px 0;
    padding: 0px 26px 0px 22px;
    /* object-fit: contain; */
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: white;
    max-height: 0;
    overflow: hidden;
    transition: all 1s cubic-bezier(0, 1, 0, 1);
  }

  .checkbox_input_insurance_container.show {
    /* margin: -2px 20px 0px; */
    padding: 0px 26px 0px 22px;
    /* object-fit: contain; */
    /* width: 77%; */
    border-radius: 0px 0px 5px 5px;
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
    background-color: white;
    height: auto;
    max-height: 9999px;
    /* overflow: visible; */
    transition: all 1s cubic-bezier(1, 0, 1, 0);
    /* z-index: 0; */
    /* width: 90%; */
    /* height: 60px; */
    /* margin: 30px 18px 0px 20px; */
    margin: 20px;
    margin-top: -2px;
  }

  ​ .sk-circle-card {
    width: 50px;
    height: 50px;
    border-radius: 100px;
    padding: 10px;
    background-color: #f5f5f5;
    margin-bottom: -70px;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    position: relative;
    box-shadow: 0 3px 6px 0 #ccc;
  }

  .recommendation_card_sk {
    height: 140px;
    margin: 100px 20px 15px;
    padding: 20px 40px 20px 12px;
    border-radius: 5px;
    background-color: #f5f5f5;
    box-shadow: 0 0.6px 2px 0 #ccc;
  }

  .row-card-sk {
    margin: 20px 10px;
    border-radius: 10px;
    background-color: #ffffff !important;
  }

  .card_sk {
    position: relative;
    overflow: hidden;
    left: 0;
    background: #ffffff;
    right: 0;
    border: 0.5px solid #f5f5f5;
    border-radius: 5px;
    margin: 10px 0px 10px;
    box-shadow: 0 1px 3px 0 #ccc;
  }

  .sk-circle-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 20px;
  }

  .sk-circle {
    width: 15px;
    height: 15px;
    border-radius: 100px;
    padding: 8px;
    background-color: orange;
    box-shadow: 0 3px 6px 0 #ccc;
    position: relative;
    top: -80px;
    /* left: 10px; */
    left: -10px;
  }

  .sk-circle2 {
    width: 10px;
    height: 10px;
    border-radius: 100px;
    padding: 8px;
    background-color: #4a90e2;
    box-shadow: 0 3px 6px 0 #ccc;
    position: relative;
    top: -90px;
    /* left: 10px; */
    left: 165px;
  }

  .sk-circle3 {
    width: 15px;
    height: 15px;
    border-radius: 100px;
    padding: 8px;
    background-color: orange;
    box-shadow: 0 3px 6px 0 #ccc;
    position: relative;
    top: -180px;
    /* left: 10px; */
    left: 185px;
  }

  .checkbox_input_family {
    width: 100%;
    height: 180px;
    box-shadow: 0 3px 6px 0 rgba(172, 172, 172, 0.23);
    background-color: #fffafa;
  }

  .personal_input {
    width: 85%;
    height: 41px;
    margin: 7px 20px 20px;
    font-size: 17px;
    padding-left: 10px;
    font-weight: 500;
    border-radius: 5px;
    border: solid 1px #d4d4d4;
    background-color: var(--white);
  }

  .personal_input:focus {
    outline: none;
  }

  .motor_main_container {
    margin-top: 20px;
  }

  /*OTP CSS*/
  .otptitle {
    margin: 20px;
    object-fit: contain;
    font-size: 18px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }

  .otp_input {
    width: 37px;
    height: 42px;
    margin-left: 15px;
    border-radius: 5px;
    border: 1px solid #eeeeee;
    background-color: #eeeeee;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.05;
    letter-spacing: normal;
    text-align: center;
    color: #e41e26;
  }

  .otp_input:focus {
    outline: none;
    background-color: #e41e251f;
    box-shadow: #e41e26;
  }

  .otpotion {
    height: 17px;
    margin-right: 20px;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.93;
    letter-spacing: normal;
    text-align: right;
    color: #e41e26;
  }

  .head {
    color: blue;
  }

  .head {
    color: #e4e4e4;
    margin: unset;
    padding: unset;
    font-size: 35px;
    padding-left: 20px;
    color: red;
  }

  .Report_container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: white;
  }

  .Report_subcontainer {
    width: 100%;
  }

  h5 {
    padding: 10px;
  }

  /* h4{
        padding: unset;
        margin: unset;
        margin-bottom: 15px;
    } */
  .other_motor_input {
    border-bottom: solid 1px #dddddd;
    border-top: unset;
    border-right: unset;
    border-left: unset;
    width: 90%;
    text-align: center;
    padding: 15px;
    font-size: 18px;
    margin-top: 20px;
    font-weight: 500;
    background: #fafafa;
  }

  .textAmount {
    text-align: center;
    width: 100%;
    padding: 10px;
    margin: unset;
    font-size: 13px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.42);
  }

  #namelabel {
    color: #8d8d8d;
    font-size: 14px;
  }

  #agelabel {
    color: #8d8d8d;
    font-size: 14px;
  }

  #citylabel {
    color: #8d8d8d;
    font-size: 14px;
  }

  .bullets {
    width: 4px;
    height: 4px;
    border-radius: 100;
    border-radius: 100px;
    margin-right: 5px;
    background-color: #e41e26;
  }

  .recommend-card-subtitle span {
    font-size: 12px;
    cursor: pointer;
  }

  .income_content {
    padding: 10px 1px 0 0;
    font-size: 12px;
    font-weight: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: rgba(0, 0, 0, 0.38);
  }

  .red-background {
    height: 225px;
    background-color: #f9eeef;
  }

  .login_logo {
    display: none;
  }

  .input_edit {
    font-size: 16px;
    font-weight: bold;
    color: #e41e26;
    padding: 30px 10px 10px 10px;
    text-align: start;
    cursor: pointer;
  }

  .existing_policy_title {
    margin: 0 39px 5px 1px;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }

  .existing_policy_subtitle {
    margin: 5px 56px 23px 2px;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: var(--warm-grey);
  }

  .existing_policy_option {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }

  .existing_policy_option_subtitle {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.7;
    letter-spacing: normal;
    text-align: left;
    color: var(--warm-grey);
  }

  .existing_policy_option_container {
    display: flex;
    align-items: center;
    /* border: 1px solid grey; */
    /* border-radius: 5px; */
    /* width: 335px; */
    /* height: 83px; */
    margin: 20px;
    padding: 5px;
    border-radius: 4px;
    border: solid 1px #d4d4d4;
    background-color: var(--white);
  }

  input {
    -webkit-writing-mode: horizontal-tb !important;
    text-rendering: auto;
    color: -internal-light-dark(green, white);
  }

  .two_wheeler_presentation {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }

  .Bike_number_input {
    border: none;
    outline: none;
    border-bottom: 0.8px solid lightgrey;
    width: 90%;
    /* height: 23px; */
    margin: 30px 1.5px 20px 6.5px;
    object-fit: contain;
    font-size: 16px;
    padding: 5px;
  }

  .Two_Wheeler_btn {
    width: 90%;
    height: 55px;
    border-radius: 2px;
    background-color: #e41e26;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    border: unset;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }

  .Two_Wheeler_bikenumber_button {
    width: 90%;
    height: 55px;
    border-radius: 2px;
    color: #e41e26;
    font-size: 16px;
    margin: 13px 2px 12px 6px;
    border-radius: 5px;
    border: solid 1px #e41e26;
    background-color: var(--white);
  }

  .brands {
    width: auto;
    height: auto;
    margin: 5px;
  }

  .model_option_container {
    height: 45px;
    margin: 10px 20px 10px 10px;
    border-radius: 5px;
    border: solid 1px #d4d4d4;
    background-color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.83;
    letter-spacing: normal;
    text-align: left;
    color: #8d8d8d;
  }

  .btn_motor {
    width: 100%;
    height: 55px;
    border-radius: 2px;
    background-color: #e41e26;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    z-index: 1;
    border: unset;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }

  .motor_policy_input {
    width: 85%;
    height: 25px;
    font-size: 20px;
    font-weight: 500;
    margin: 0px 10px 10px 10px;
    outline: none;
    border: none;
    border-bottom: solid 2px #e41e26;
    background-color: transparent;
  }
}

/* On screens that are 600px wide or less, the background color is olive */

@media screen and (max-width: 600px) {

  @keyframes slide {
    from {
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
    }

    to {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
    }
  }

  .app-provider {
    -webkit-animation: slide-back 0.5s;
            animation: slide-back 0.5s;
  }

  body {
    background-color: #ffffff;
    font-family: 'Poppins', sans-serif;
  }

  .Mainapp {
    width: 100%;
  }

  #webview {
    display: none;
  }

  .total-age[_ngcontent-che-c1] {
    position: absolute;
    top: 14px;
    color: #252733;
    background: #eaedfc;
    width: 85px;
    right: 10px;
    text-align: center;
    display: block;
    border-radius: 5px;
    padding: 6px;
    font-weight: 700;
  }

  .header-area {
    height: 40px;
    overflow: hidden;
    display: block;
    display: flex;
    align-items: center;
    z-index: 1;
    width: 100%;
    padding: 7px 0px 7px 0px;
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 14%);
    background-color: #ffffff;
    position: fixed;
    top: 0;
    z-index: 2;
    width: 100%;
  }

  .header-area:after {
    content: "";
    display: table;
    clear: both;
  }

  .quotes_api_failure_container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
  }

  .pb_unset {
    padding-bottom: unset !important;
  }

  .quotes_api_failure_subcontainer {
    width: 40%;
  }

  .quotes_api_failure_subcontainer span {
    color: #000;
    font-weight: bold;
    font-size: 12px;
    padding: 15px 0px 15px 0px;
  }

  .header-text {
    padding-left: 10px;
    opacity: 0.71;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }

  .recommendation_header {
    height: 19px;
    margin-left: 10px;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }

  .progress-container {
    width: 100%;
    height: 2px;
    background: #ccc;
    position: absolute;
    bottom: 0;
  }

  .progress-bar {
    height: 2px;
    background: #e41e26;
    width: 0%;
  }

  .back-icon {
    float: left;
    height: 27px;
    padding-left: 10px;
  }

  .page-title {
    /* margin: 80px 10px 20px 20px; */
    /* font-family: SFProText; */
    font-size: 18px;
    padding: 20px;
    padding-top: 10px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }

  .page-subtitle {
    width: 90%;
    margin: 0px auto 0px auto;
    opacity: 0.77;
    /* font-family: SFProText; */
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }

  .credit-score {
    width: 31%;
    height: 120px;
    line-height: 2.33;
    border-radius: 50%;
    /* font-family: SFProText; */
    font-size: 52px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    margin: 29px auto 10px auto;
    color: #e41e26;
    text-align: center;
    letter-spacing: normal;
    background: #ffffff;
    border: 10px solid #f2f2f2;
  }

  .credit-title {
    opacity: 0.54;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }

  .credit-name {
    font-size: 18px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    margin-top: 45px;
    letter-spacing: normal;
    text-align: center;
    color: #e41e26;
  }

  .credit-subtitle {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: rgba(0, 0, 0, 0.77);
  }

  .credit-details {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    margin-top: 45px;
    letter-spacing: normal;
    text-align: center;
    color: rgba(0, 0, 0, 0.77);
  }

  .page-content {
    width: 90%;
    /* height: 45px; */
    margin: 0px auto 20px auto;
    /* font-family: SFProText; */
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }

  .slider {
    width: 90%;
  }

  .salary-input {
    width: 90%;
    background-color: #fcfcfc;
    border: 0;
    border-bottom: 2px solid lightgrey;
    padding: 10px;
    /* margin-top: 10%; */
    margin-left: 10px;
  }

  .suminsured-input {
    width: 90%;
    background-color: #fcfcfc;
    border: 0;
    border-bottom: 2px solid lightgrey;
    padding: 10px;
    /* margin-top: 10%; */
    margin-left: 15px;
  }

  .suminsured:focus {
    outline: none;
  }

  .next-btn {
    /* display: block; */
    margin-top: 15px;
    width: 100%;
    border: none;
    background-color: #e41e26;
    color: white;
    padding: 14px 28px;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
  }

  .main-page {
    margin-top: 55px;
  }

  input[type="radio" i] {
    margin: unset;
    accent-color: #e41e26;
  }

  .red-background {
    height: 225px;
    background-color: #f9eeef;
  }

  .box {
    box-sizing: border-box;
    float: left;
    width: 50%;
    height: 100%;
  }

  .input-box {
    box-sizing: border-box;
    z-index: 2;
    width: 100%;
    height: 320px;
    margin: -100px auto 70px auto;
    padding: 11px 15px 34px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    background-color: white;
  }

  .health-input {
    margin: 0px auto 0px auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .health-svg-family {
    height: 120px;
    margin-top: 25px;
  }

  .health-svg {
    height: 70px;
    margin-top: 10px;
  }

  .assets-icon {
    height: 50px;
  }

  .assets-checkbox {
    height: 50px;
    margin-right: 10px;
  }

  .vehicle-name {
    opacity: 0.77;
    /* font-family: SFProText; */
    color: #000000;
    font-size: 15px;
  }

  /* The container */
  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 20px;
    left: 20px;
    height: 15px;
    width: 15px;
    background-color: #ffffff;
    border: 1px solid #707070;
    margin-right: 10px;
    border-radius: 4px;
  }

  /* When the checkbox is checked, add a green background */
  .container input:checked~.checkmark {
    background-color: #92bf61;
    border: 1px solid #92bf61;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked~.checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 4px;
    top: 0px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .min_amt {
    margin-top: 5px;
    opacity: 0.6;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.08;
    letter-spacing: normal;
    float: left;
    color: #000000;
    /* font-family: SFProText; */
  }

  .max_amt {
    margin-top: 5px;
    opacity: 0.6;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.08;
    letter-spacing: normal;
    float: right;
    color: #000000;
    /* font-family: SFProText; */
  }

  .Familydetails_maincomponent {
    width: 100%;
    /* padding: 0 0 28px; */
    background-color: #ffffff;
  }

  .Familydetails_maincomponent_family {
    width: 100%;
    padding-top: 60px;
    padding-bottom: 50px;
    background-color: white;
  }

  .insurance_category {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    margin: 20px;
    margin-bottom: unset;
    padding: 19.5px 0px 15px 0px;
    border-radius: 5px;
    /* border: solid 1px #dddddd; */
    background-color: #fffafa;
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 11%);
  }

  .insurance_category div {
    display: flex;
    align-items: center;
  }

  .insurance_cat_headernone {
    width: 100%;
    margin: 2.5px 178px 7px 15px;
    opacity: 0.81;
    /* font-family: SFProText; */
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }

  .insurance_cat_icon {
    width: 24px;
    height: 24px;
    margin: 0.5px 20px 10px 178px;
    object-fit: contain;
    opacity: 0.14;
  }

  .credit_score_container {
    display: flex;
    align-items: center;
    background-color: #f2f2f2;
    padding: 20px 20px 20px 20px;
    margin-bottom: 20px;
  }

  .credit_score {
    width: 32px;
    height: 29px;
    /* font-family: SFProText; */
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #e41e26;
    margin: 15px;
    padding: 15px;
    border: solid 6px #f2f2f2;
    background-color: white;
    border-radius: 100px;
  }

  /*Rahul */
  h5 {
    margin: unset;
    padding: unset;
  }

  h4 {
    margin: unset;
    padding: unset;
  }

  h3 {
    margin: unset;
    padding: unset;
  }

  .onbording_content {
    padding-top: 5px;
    padding-left: 10px;
    padding-bottom: 45px;
  }

  .content-insurance-details {
    margin-left: 20px;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
  }

  .content {
    margin: 20px;
    /* font-family: SFProText; */
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    color: #acacac;
  }

  .btn {
    width: 100%;
    height: 50px;
    border-radius: 2px;
    background-color: #e41e26;
    color: #fff;
    font-size: 16px;
    position: fixed;
    font-weight: 500;
    bottom: 0;
    z-index: 1;
    font-family: "Poppins";
    border: unset;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }

  .nav_header {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    margin: 0 0 1px;
    padding: 13px 196px 13px 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.14);
    background-color: #ffffff;
  }

  .family_nav {
    width: 200px;
    height: 19px;
    margin: 3px 0 2px 15px;
    opacity: 0.71;
    /* font-family: SFProText; */
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }

  .checkbox_input {
    margin-bottom: 18px;
    width: 32%;
    height: 115px;
    box-shadow: 0 3px 6px 0 rgb(172 172 172 / 23%);
    background-color: #ffc0cb12;
  }

  .checkbox_input_home {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
  }

  .family_checkbox {
    width: 18px;
    height: 18px;
    border-radius: 4px;
    border: solid 1px #707070;
    background-color: #ffffff;
    accent-color: #e41e26;
  }

  .assets_checkbox {
    width: 18px;
    height: 18px;
    border-radius: 4px;
    border: solid 1px #707070;
    background-color: #ffffff;
    accent-color: #e41e26;
  }

  /* .assets-svg {
        margin: 15px 0px 0px -23px;
    } */

  .number {
    margin: 5px;
    padding: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 50%;
  }

  .motor_number {
    margin-top: 10px;
    width: 100%;
  }

  .minus,
  .plus {
    padding: 10px;
    margin-right: 10px;
    font-size: 4vw;
    display: inline-block;
    font-weight: 500;
    text-align: right;
    float: right;
    /* color: #e4e4e4; */
    color: #e41e26;
  }

  .plusMinusInput {
    height: 21px;
    width: 35%;
    text-align: center;
    font-size: 15px;
    border: 1px solid #ddd;
    display: inline-block;
    vertical-align: middle;
  }

  .motor_input {
    border-top: unset;
    border-bottom: unset;
    border-left: unset;
    padding: 8px;
    font-size: 14px;
    font-family: "Poppins";
    width: 75%;
    margin-left: 10px;
    height: 25px;
    border-right: unset;
    border-bottom: 1px solid #e41e26;
  }

  .motor_input2 {
    border-top: unset;
    border-bottom: unset;
    border-left: unset;
    padding: 8px;
    font-size: 4vw;
    width: 75%;
    height: 25px;
    font-weight: 500;
    border-right: unset;
    border-bottom: 1px solid #e41e26;
  }

  .motor_input2:focus {
    outline: none;
  }

  .motor_input:focus {
    outline: none;
  }

  .checkbox_title {
    object-fit: contain;
    opacity: 0.81;
    font-size: 12px;

    font-weight: bolder;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    padding: unset;
    margin: unset;
    margin-left: 10px;
  }

  .checkbox_subtitle {
    width: 300px;

    /* font-family: SFProText; */
    font-size: 16px;
    /* padding-bottom: 10px; */
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.69;
    letter-spacing: normal;
    text-align: left;
    color: #00000031;
  }

  .family_input {
    border-bottom: solid 1px #dddddd;
    border-top: unset;
    border-right: unset;
    border-left: unset;
    padding: 8px;
    margin-left: 18px;
    width: 85%;
    transition: all 0.5s ease;
  }

  .column-card-premium {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  /* .familydate_input {
    width: 85%;
    height: 20px;
    margin: 0 7px 0 0;
    font-size: 20px;
    font-weight: 500;
    padding: 9px 10px 9px 11px;
    outline: none;
    border: none;
    border-bottom: solid 2px #e41e26;
    background-color: transparent;
  } */
  .familydate_input {
    font-size: 20px;
    width: 85%;
    font-weight: 500;
    padding: 9px 10px 9px 11px;
    outline: none;
    border: none;
    border-bottom: solid 2px #e41e26;
  }

  .familydate_input::-webkit-input-placeholder {
    color: lightgrey;
  }

  .familydate_input::placeholder {
    color: lightgrey;
  }

  .family_input:focus {
    outline: none;
  }

  /* .insurance_category {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 20px;
    margin: 30px 20px 20px;
    padding: 19.5px 0px 15px 0px;
    border-radius: 5px;
    border: solid 1px #dddddd;
    background-color: #ffffff;
  } */
  .insurance_category div {
    display: flex;
    align-items: center;
  }

  .insurance_cat_header {
    width: 100px;
    margin: 0px 25px 7px 15px;
    opacity: 0.81;
    /* font-family: SFProText; */
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }

  .insurance_cat_headernone {
    width: 100%;
    height: 30px;
    margin: 30px 20px 20px;
    opacity: 0.81;
    /* font-family: SFProText; */
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }

  .insurance_cat_icon {
    width: 24px;
    height: 24px;
    margin: 0.5px 20px 10px 178px;
    object-fit: contain;
    opacity: 0.14;
  }

  .credit_score_container {
    display: flex;
    align-items: center;
    height: 140px;
    background-color: #f2f2f2;
    padding: 20px 20px 20px 20px;
    margin-top: 18%;
    background-color: #ecf5e3;
  }

  .credit_score {
    width: 32px;
    height: 29px;
    /* font-family: SFProText; */
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #e41e26;
    margin: 15px;
    padding: 15px;
    border: solid 6px #f2f2f2;
    background-color: white;
    border-radius: 100px;
  }

  .credit-score-title {
    width: 193px;
    object-fit: contain;
    opacity: 0.77;
    /* font-family: SFProText; */
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }

  .credit-score-number {
    margin: 15px 0px 15px;
  }

  .super_top_up_main_container {
    width: 100%;
    height: auto;
    padding-bottom: 80px;
    background-color: #ffffff;
  }

  .header {
    display: none;
  }

  .category_insurance_header {
    height: 43px;
    margin: 10px 33px 30px 27px;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }

  .progress {
    width: 10px;
    height: 1px;
  }

  .progress_container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .suminsured_container {
    width: 90%;
    height: 245px;
    padding: 1px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    background-color: var(--white);
  }

  .slider {
    width: 90%;
    margin-top: 35px;
  }

  .rangeinput {
    color: lightgrey;
    font-size: 15px;
    padding-right: 10px;
  }

  .dot-floating {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #e41e26;
    color: #e41e26;
    -webkit-animation: dotFloating 3s infinite cubic-bezier(0.15, 0.6, 0.9, 0.1);
            animation: dotFloating 3s infinite cubic-bezier(0.15, 0.6, 0.9, 0.1);
  }

  .dot-floating::before,
  .dot-floating::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }

  .dot-floating::before {
    left: -12px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #e41e26;
    color: #e41e26;
    -webkit-animation: dotFloatingBefore 3s infinite ease-in-out;
            animation: dotFloatingBefore 3s infinite ease-in-out;
  }

  .dot-floating::after {
    left: -24px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #e41e26;
    color: #e41e26;
    -webkit-animation: dotFloatingAfter 3s infinite cubic-bezier(0.4, 0, 1, 1);
            animation: dotFloatingAfter 3s infinite cubic-bezier(0.4, 0, 1, 1);
  }

  @-webkit-keyframes dotFloating {
    0% {
      left: calc(-50% - 5px);
    }

    75% {
      left: calc(50% + 105px);
    }

    100% {
      left: calc(50% + 105px);
    }
  }

  @keyframes dotFloating {
    0% {
      left: calc(-50% - 5px);
    }

    75% {
      left: calc(50% + 105px);
    }

    100% {
      left: calc(50% + 105px);
    }
  }

  @-webkit-keyframes dotFloatingBefore {
    0% {
      left: -50px;
    }

    50% {
      left: -12px;
    }

    75% {
      left: -50px;
    }

    100% {
      left: -50px;
    }
  }

  @keyframes dotFloatingBefore {
    0% {
      left: -50px;
    }

    50% {
      left: -12px;
    }

    75% {
      left: -50px;
    }

    100% {
      left: -50px;
    }
  }

  @-webkit-keyframes dotFloatingAfter {
    0% {
      left: -100px;
    }

    50% {
      left: -24px;
    }

    75% {
      left: -100px;
    }

    100% {
      left: -100px;
    }
  }

  @keyframes dotFloatingAfter {
    0% {
      left: -100px;
    }

    50% {
      left: -24px;
    }

    75% {
      left: -100px;
    }

    100% {
      left: -100px;
    }
  }

  .header_labels {
    width: 216px;
    height: 21px;
    margin: 20px;
    object-fit: contain;
    /* font-family: SFProText; */
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }

  .other_checkbox_input {
    display: flex;
    align-items: center;
  }

  .other_family_input {
    border-bottom: solid 1px #dddddd;
    border-top: unset;
    border-right: unset;
    border-left: unset;
    width: 70%;
    text-align: center;
    padding: 8px;
    font-size: 25px;
    font-weight: 500;
    background: white;
  }

  .mobile_text_field {
    border: solid 0.7px #dddddd;
    width: 87%;
    font-family: "Poppins";
    height: 22px;
    padding: 10px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
  }

  .warning_title {
    margin: 10px 5px 20px 15px;
    width: 90%;
    color: lightgrey;
    font-size: 12px;
  }

  .mobile_text_field:focus {
    outline: none;
    border: solid 1px #e41e26;
  }

  .other_family_input:focus {
    outline: none;
  }

  .insurance_checking {
    padding: 20px 22PX;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
  }

  .recommendation_card {
    width: 100%;
    /* margin: 15px; */
    margin-top: 50px;
    padding: 12px;
    border-radius: 5px;
    background-image: linear-gradient(to bottom, #f1ffe2, #fafafa);
    box-shadow: 0 3px 6px 0 rgba(212, 212, 212, 0.5);
  }

  .circle {
    position: relative;
    width: 50%;
    padding-bottom: 50%;
    background: gold;
    border-radius: 100%;
  }

  .circle h3 {
    position: absolute;
    top: 45%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    margin: 0;
    font-size: 35px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.9;
    letter-spacing: normal;
    text-align: center;
    color: white;
  }

  .sideCircle {
    background: #febc4e;
    width: 25px;
    height: 25px;
    border-radius: 100px;
    position: relative;
    top: -80px;
    left: 148px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  }

  .sideCircle2 {
    background: #febc4e;
    width: 20px;
    height: 20px;
    border-radius: 100px;
    position: relative;
    top: -120px;
    left: 320px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  }

  .home_input_opt {
    font-size: 4vw;
    display: flex;
    font-weight: 600;
    /* margin: 5px; */
    /* padding: 5px 20px 10px; */
    padding-left: 11px;
  }

  .home_value_input {
    font-weight: bold;
    padding-top: unset;
    padding-right: unset;
    padding-bottom: unset;
    padding-left: 10px;
    margin: unset;
    font-size: 4.5vw;
    display: flex;
  }

  .sideCircle3 {
    background: #4a90e2;
    width: 20px;
    height: 20px;
    border-radius: 100px;
    position: relative;
    top: -95px;
    left: 295px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  }

  .circle p {
    position: absolute;
    top: 65%;
    left: 50%;
    width: 100%;
    font-size: 12px;
    color: white;
    text-align: center;
    -webkit-transform: translate(-50%, -65%);
            transform: translate(-50%, -65%);
    margin-top: 15px;
  }

  .card {
    position: relative;
    overflow: hidden;
    left: 0;
    background: #ffffff;
    right: 0;
    padding: 4px;
    padding-bottom: unset;
    border: 0.5px solid #ededed;
    border-radius: 5px 5px 0px 0px;
    margin: 10px 0px 0px;
  }

  .total-age {
    color: #252733;
    background: #fffafa;
    width: 85px;
    margin-right: 10px;
    text-align: center;
    display: block;
    border-radius: 5px;
    padding: 6px;
    font-size: 11px;
    font-weight: 700;
  }

  .view-details {
    text-align: center;
    border-bottom: solid 1px #ededed;
    border-radius: 0px 0px 5px 5px;
    border-right: solid 1px #ededed;
    border-left: solid 1px #ededed;
    padding: 14px;
    font-size: 3.5vw;
    color: #e41e26;
  }

  .view-details-content {
    max-height: 0;
    overflow: hidden;
    transition: all 1s cubic-bezier(0, 1, 0, 1);
  }

  .view-details-content.show {
    height: auto;
    max-height: 9999px;
    overflow: visible;
    transition: all 1s cubic-bezier(1, 0, 1, 0);
  }

  .recommend-card-title {
    margin-left: 10px;
    margin-bottom: 5px;
    /* font-family: SFProText; */
    font-size: 13px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    color: black;
  }

  .recommend-card-amt {
    margin: 0 0px 0px 15px;
    /* font-family: SFProText; */
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    color: #e41e26;
  }

  .recommend-card-amt-black {
    margin: 0 0px 0px 15px;
    /* font-family: SFProText; */
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    padding-right: 10px;
    color: #64a819;
  }

  .recommend-card-subtitle {
    margin-left: 10px;
    display: flex;
    align-items: center;
    /* font-family: SFProText; */
    color: #000000;
    opacity: 0.54;
    font-size: 3vw;
  }

  .recommend-product-title {
    margin-left: 12px;
    float: left;
    /* font-family: SFProText; */
    color: #000000;
    opacity: 0.54;
    font-size: 12px;
    font-weight: normal;
  }

  .recommend-product {
    margin-left: 15px;
    float: left;
    /* font-family: SFProText; */
    color: #000000;
    font-size: 14px;
    font-weight: 600;
  }

  .subtitle-span {
    border: 0.5px solid gray;
    background: white;
    margin-right: 5px;
  }

  .column-card-1 {
    float: left;
    width: 15%;
    padding: 15px 0px 15px;
  }

  .icon-inner,
  .ionicon,
  svg {
    cursor: pointer;
  }

  .column-card-2 {
    display: flex;
    padding: 18px 20px 15px 27px;
  }

  .insurance_status {
    display: flex;
    justify-content: center;
    color: #e41e26;
    text-align: center;
    font-size: 12px;
  }

  .column-card-3 {
    /* float: left; */
    width: 100%;
  }

  .column-card-4 {
    /* float: left; */
    width: 50%;
  }

  .column-card-cover {
    float: left;
    background-color: #f5f5f5;
    padding: 10px 0 10px 0;
  }

  .column-card-cover-left {
    /* border-right: 5px solid #ffffff;
        width: 48%; */
    width: 49%;
    margin-right: 5px;
  }

  .column-card-cover-right {
    /* border-left: 5px solid #ffffff;
        width: 48%; */
    width: 49%;
  }

  .row-card:after {
    content: "";
    display: table;
    clear: both;
  }

  .kompass_logo {
    display: flex;
    justify-content: flex-start;
    position: fixed;
    top: -10px;
    left: 1px;
    margin-top: 15px;
    margin-bottom: 20px;
  }

  .kompass_logo img {
    width: 130px;
    height: auto;
    margin: 15px 0 0 10px
  }

  .row-card {
    background-color: #fef3f4 !important;
  }

  .row-card-1 {
    margin: 5px 0px;
    margin-bottom: unset;
    background-color: #ffffff !important;
    display: flex;
    justify-content: center;
  }

  .row-card-2 {
    margin: 10px 10px;
    background-color: #ffffff !important;
    display: flex;
    justify-content: space-between;
  }

  .recommend-card-data {
    font-size: 14px;
    color: black;
    text-align: left;
    font-weight: 600;
  }

  .recommend-card-gst {
    font-size: 22px;
    font-weight: 600;
    color: #64a819;
    opacity: 0.81;
    /* font-family: SFProText; */
  }

  .recommendation_options_list {
    margin-top: 160px;
  }

  .cover-text-premium {
    opacity: 0.44;
    /* font-family: SFProText; */
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.69;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }

  .cover-text {
    opacity: 0.44;
    /* font-family: SFProText; */
    font-size: 3vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.69;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    padding: unset;
    margin: unset;
  }

  .income_amount_container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }

  .rcard_name {
    color: black;
    padding-bottom: 7px;
    text-align: left;
  }

  .rcard_content {
    color: black;
    font-weight: 700;
    font-weight: lighter;
    opacity: 0.6;
    font-size: 14px;
    text-align: left;
  }

  .item1 {
    grid-area: svg;
    height: 140px;
    text-align: left;
    margin-top: -15px;
    margin-left: -25px;
  }

  .item2 {
    grid-area: score;
    text-align: center;
    height: 140px;
    margin-right: 28px;
  }

  .grid-container {
    display: grid;
    grid-template-areas:
      "svg score score"
      "svg score score";
  }

  .card_creditscroe {
    /* width: 52px; */
    margin: 12px 4px 4px 0;
    /* font-family: SFProText; */
    font-size: 40px;
    font-weight: bolder;
    line-height: 0.7;
    letter-spacing: 0.4px;
    /* text-align: left; */
    color: black;
    z-index: 2;
    text-align: center;

    opacity: 0.6;
  }

  .score_status {
    width: 72%;
    margin: -35px -4px 0px 123px;
    /* font-family: SFProText; */
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: black;
  }

  .card_suggestion {
    /* font-family: SFProText; */
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #64a819;
    text-decoration: underline;
    padding-bottom: unset;
    margin-bottom: unset;
  }

  .recommendations_heading {
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    font-size: 16px;
    font-weight: 700;
  }

  .recommendation-car-svg {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .recommendation-card-svg {
    z-index: 1;
    position: relative;
    width: 40px;
  }

  .multiple_input {
    font-weight: bold;
    padding: unset;
    margin: unset;
    font-size: 3.5vw;
    text-align: left;
  }

  .single_input {
    font-weight: bold;
    padding: unset;
    margin: unset;
    font-size: 4vw;
    padding-left: 10px;
  }

  .skeleton-h99tl35omha:empty {
    box-sizing: content-box;
    height: 30px;
    width: 120px;
    height: 30px;
    background-color: whitesmoke;
    border-radius: 5px 5px 5px 5px;
    background-image: linear-gradient(100deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 80%),
      linear-gradient(#f2f0f0 30px, transparent 0);
    background-repeat: repeat-y;
    background-size: 50px 170px, 100px 170px;
    background-position: 0 0, 70px 15px;
    -webkit-animation: shineForSkeleton-h99tl35omha 2s infinite;
            animation: shineForSkeleton-h99tl35omha 2s infinite;
  }

  @-webkit-keyframes shineForSkeleton-h99tl35omha {
    to {
      background-position: 100% 0, 70px 15px;
    }
  }

  @keyframes shineForSkeleton-h99tl35omha {
    to {
      background-position: 100% 0, 70px 15px;
    }
  }

  .suggested_category {
    width: 100px;
    height: 20px;
    color: white;
    padding: 14px 34.7px 15px 33px;
    border-radius: 2px;
    background-color: white;
  }

  .suggested_category p {
    padding: unset;
    margin: unset;
    color: black;
  }

  .recommendation_options {
    width: 100%;
    /* height: 45px; */
    margin: 30px 1px 20px;
    /* padding-left: 10px; */
    border-radius: 5px;
    display: flex;
    align-items: center;
    background-color: #ffffff;
  }

  .recommendation_options span {
    width: 100%;
    height: 16px;
    margin: 0px 0 4px 12px;
    opacity: 0.81;
    /* font-family: SFProText; */
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  .company_info {
    margin: 0px 20px 10px;
    opacity: 0.54;
    /* font-family: SFProText; */
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }

  .otp_verification {
    width: 335px;
    height: 36px;
    margin: 1px 0.5px 10px 0;
    padding: 20px;
    opacity: 0.81;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }

  .pop4 {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    background-color: rgba(0, 0, 0, 0.65);
    bottom: 0;
    flex-direction: column;
    overflow-y: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
  }

  .Family_animation {
    position: absolute;
    /* bottom: 35px; */
    /* bottom: 0px; */
    width: 100%;
  }

  .pop3 {
    width: 100%;
    display: -webkit-flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
  }

  .pop2 {
    background-color: rgba(255, 255, 255, 1);
    background-color: rgba(var(--f23, 255, 255, 255), 1);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    max-height: 100%;
    overflow: hidden;
    display: -webkit-flex;
    flex-direction: column;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .bottom_btn_container {
    display: flex;
    justify-content: center;
  }

  .stage {
    margin: 12px 0px 5px 0px;
  }

  .pop1 {
    justify-content: center;
    display: -webkit-flex;
    flex-direction: column;
    background-color: white;
  }

  .popupicon1 {
    font-size: 50px;
    color: #075e54;
    cursor: pointer;
  }

  .popupicon2 {
    font-size: 50px;
    color: red;
    cursor: pointer;
  }

  .otpverification {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid lightgrey;
    width: 335px;
    padding: 5px;
  }

  /* .btn-group button {
        background-color: #ffffff;
        border: 1px solid #ffffff;
        color: black;
        padding: 10px 24px;
        cursor: pointer;
        float: left;
        z-index: 1;
    } */
  /* .btn-group:after {
        content: "";
        clear: both;
        display: table;
    } */
  .tab-btn {
    width: 50%;
    padding: 15px;
    background-color: #ffffff;
    color: black;
    cursor: pointer;
    border: none;
    outline: none;
    font-family: "Poppins";
    z-index: 1;
    border-radius: 5px;
  }

  .tab-btn-focus {
    width: 50%;
    padding: 15px;
    outline: none;
    font-weight: 500;
    border: none;
    font-family: "Poppins";
    border-radius: 5px;
    background-color: rgba(248, 234, 234, 1);
    color: #e41e26;
  }

  .tab-btn-focus:focus {
    border: none;
    outline: none;
  }

  #sc {
    width: 40px;
    height: 40px;
    border-radius: 100px;
    background: linear-gradient(90deg, #ededed, #ffffff, #ededed);
    -webkit-animation-name: load;
            animation-name: load;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-direction: forwards;
            animation-direction: forwards;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
  }

  #sc2 {
    width: 80%;
    padding: 3px;
    border-radius: 5px;
    margin: 5px;
    background: linear-gradient(90deg, #ededed, #ffffff, #ededed);
    -webkit-animation-name: load;
            animation-name: load;
    -webkit-animation-duration: 1.5ms;
            animation-duration: 1.5ms;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-direction: forwards;
            animation-direction: forwards;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
  }

  #sc1 {
    width: 40%;
    height: 7px;
    border-radius: 5px;
    margin: 5px;
    background: linear-gradient(90deg, #ededed, #ffffff, #ededed);
    -webkit-animation-name: load;
            animation-name: load;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-direction: forwards;
            animation-direction: forwards;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
  }

  .fade-in {
    -webkit-animation: fadeIn 1s;
            animation: fadeIn 1s;
  }

  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
      transition: opacity 250ms ease-in;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transition: opacity 250ms ease-in;
    }

    to {
      opacity: 1;
    }
  }

  #slide {
    position: relative;
    animation: fadeIn 1s;
    -webkit-animation: fadeIn ease 0.5s;
    -moz-animation: fadeIn ease 0.5s;
    -o-animation: fadeIn ease 0.5s;
    -ms-animation: fadeIn ease 0.5s;
  }

  @-webkit-keyframes slide-back {
    from {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
    }

    to {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
    }
  }

  @keyframes slide-back {
    from {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
    }

    to {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
    }
  }

  @-webkit-keyframes slide-front {
    from {
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
    }

    to {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
    }
  }

  @keyframes slide-front {
    from {
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
    }

    to {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
    }
  }

  .recommendation_card_for_animation {
    -webkit-animation: slide-back 0.5s;
            animation: slide-back 0.5s;
  }

  .recommendation_card_for_animation_others {
    -webkit-animation: slide-front 0.5s;
            animation: slide-front 0.5s;
  }

  /* .slide_top {
    position: relative;
    right: -100%;
    -webkit-animation: slide 0.5s forwards;
    -webkit-animation-delay: 0.5s;
    animation: slide 0.5s forwards;
    animation-delay: 0.5s;
  } */
  #city_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    padding: 15px;
    padding-left: unset;
    background-color: white;
    width: 90%;
  }

  .city_search {
    border: none;
    outline: none;
    padding: 15px;
    width: 91%;
    margin-top: 15px;
    font-size: 16px;
    border-radius: 8px;
    font-weight: 500;
    background-color: rgba(198, 198, 198, 0.23);
  }

  .city_search:focus {
    outline: none;
    border: none;
  }

  @-webkit-keyframes slide {
    100% {
      right: 0;
    }
  }

  @keyframes slide {
    100% {
      right: 0;
    }
  }

  @-webkit-keyframes slide_top {
    100% {
      top: 0;
    }
  }

  @keyframes slide_top {
    100% {
      top: 0;
    }
  }

  .circular-chart {
    display: block;
    margin: 0px auto;
    max-width: 80%;
    max-height: 250px;
  }

  .circle-bg {
    fill: none;
    stroke: black;
    opacity: 0.05;
    stroke-width: 3.8;
  }

  .circle {
    fill: none;
    stroke-width: 2.8;
    stroke-linecap: round;
    -webkit-animation: progress 1s ease-out forwards;
            animation: progress 1s ease-out forwards;
  }

  @-webkit-keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }

  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }

  .circular-chart.green .circle {
    stroke: #92bf61;
  }

  .percentage {
    fill: #92bf61;
    font-size: 14px;
    font-weight: 600;
    text-anchor: middle;
  }

  .onboarding_sub_content {
    width: 300px;
    margin: 5.7px 51px 5px 24px;
    object-fit: contain;
    font-size: 23px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }

  .insurance_button {
    width: 90%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid lightgrey;
    background-color: white;
    font-size: 4vw;
    font-weight: 500;
    position: fixed;
    bottom: 3%;
  }

  .insurance_btn {
    width: 90%;
    height: 50px;
    border-radius: 5px;
    background-color: #e41e26;
    color: white;
    font-weight: 500;
    font-size: 4vw;
    position: fixed;
    font-family: "Poppins";
    bottom: 90px;
    border: unset;
  }

  .onboarding_next-btn {
    width: 40%;
    height: 50px;
    border-radius: 5px;
    background-color: #e41e26;
    color: white;
    font-weight: 600;
    font-size: 16px;
    padding: 10px;
    margin-left: 20px;
    margin-top: 10px;
    font-family: "Poppins";
    border: unset;
  }

  .familydetails_content {
    margin: 20px;
    object-fit: contain;
    opacity: 0.77;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.09;
    letter-spacing: normal;
    color: #000000;
  }

  /* .family_input {
    width: 80%;
    margin: 0px auto 22px auto;
    padding: 15px;
    box-shadow: 0 3px 6px 0 rgba(172, 172, 172, 0.34);
    background-color: var(--white);
    transition: all 0.5s ease;
  } */
  .family_input {

    background-color: var(--white);
  }

  .family_age_warning {
    display: flex;
    justify-content: center;
    top: 10px;
    width: 100%;
    left: 0;
    right: 0;
    margin-bottom: 10px;
  }

  .assets_input {
    padding: 10px;
  }

  .assets_input_right {
    padding: 10px;
  }

  input[type="range"] {}

  input[type="range"]::-webkit-slider-runnable-track {
    width: 300px;
    height: 5px;
    background: #e41e26;
    border: none;
    border-radius: 3px;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 0.5px solid gray;
    height: 25px;
    background: #e41e26;
    width: 25px;
    border-radius: 50%;
    background: #ffffff;
    margin-top: -6px;

  }

  .existingInsurance_pages {
    display: flex;
    justify-content: center;
    margin-top: 25%;
  }

  input[type="range"]:focus {
    outline: none;
  }

  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #ccc;
  }

  .​checkbox_input_insurance {
    display: flex;
    align-items: center;
    padding-bottom: 7%;
  }

  .checkbox_input_insurance_container {
    margin: 5px 22px 0;
    padding: 0px 26px 0px 22px;
    /* object-fit: contain; */
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: white;
    max-height: 0;
    overflow: hidden;
    transition: all 1s cubic-bezier(0, 1, 0, 1);
  }

  .checkbox_input_insurance_container.show {
    /* margin: -2px 20px 0px; */
    padding: 0px 26px 0px 22px;
    /* object-fit: contain; */
    /* width: 77%; */
    border-radius: 0px 0px 5px 5px;
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
    background-color: white;
    height: auto;
    max-height: 9999px;
    /* overflow: visible; */
    transition: all 0.6s cubic-bezier(1, 0, 1, 0);
    /* z-index: 0; */
    /* width: 90%; */
    /* height: 60px; */
    /* margin: 30px 18px 0px 20px; */
    margin: 20px;
    margin-top: -2px;
  }

  ​ .personal_details_madatory_fileds {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    color: #757575;
  }

  .sk-circle-card {
    width: 50px;
    height: 50px;
    border-radius: 100px;
    padding: 10px;
    background-color: #f5f5f5;
    margin-bottom: -70px;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    position: relative;
    box-shadow: 0 3px 6px 0 #ccc;
  }

  .recommendation_card_sk {
    height: 140px;
    margin: 100px 20px 15px;
    padding: 20px 40px 20px 12px;
    border-radius: 5px;
    background-color: #f5f5f5;
    box-shadow: 0 0.6px 2px 0 #ccc;
  }

  .row-card-sk {
    margin: 20px 10px;
    border-radius: 10px;
    background-color: #ffffff !important;
  }

  .insurance_details_icon {
    padding-top: 150px;
  }

  .card_sk {
    position: relative;
    overflow: hidden;
    left: 0;
    background: #ffffff;
    right: 0;
    border: 0.5px solid #f5f5f5;
    border-radius: 5px;
    margin: 10px 0px 10px;
    box-shadow: 0 1px 3px 0 #ccc;
  }

  .sk-circle-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 20px;
  }

  .sk-circle {
    width: 15px;
    height: 15px;
    border-radius: 100px;
    padding: 8px;
    background-color: orange;
    box-shadow: 0 3px 6px 0 #ccc;
    position: relative;
    top: -80px;
    /* left: 10px; */
    left: -10px;
  }

  .sk-circle2 {
    width: 10px;
    height: 10px;
    border-radius: 100px;
    padding: 8px;
    background-color: #4a90e2;
    box-shadow: 0 3px 6px 0 #ccc;
    position: relative;
    top: -90px;
    /* left: 10px; */
    left: 165px;
  }

  .sk-circle3 {
    width: 15px;
    height: 15px;
    border-radius: 100px;
    padding: 8px;
    background-color: orange;
    box-shadow: 0 3px 6px 0 #ccc;
    position: relative;
    top: -180px;
    /* left: 10px; */
    left: 185px;
  }

  .checkbox_input_family {
    width: 100%;
    position: relative;
    height: 180px;
    box-shadow: 0 3px 6px 0 rgba(172, 172, 172, 0.23);
    background-color: #fffafa;
  }

  .checkbox_input_family_display {
    width: 100%;
    height: auto;
    min-height: 180px;
    box-shadow: 0 3px 6px 0 rgba(172, 172, 172, 0.23);
    background-color: white;
  }

  .input_display {
    font-size: 16px;
    padding: 10px 10px 20px 10px;
    text-align: left;
  }

  .input_display_2 {
    font-size: 4vw;
    padding: 10px;
    color: "grey";
  }

  .input_edit {
    font-size: 5vw;
    font-weight: bold;
    color: #e41e26;
    padding: 30px 10px 10px 10px;
    text-align: start;
    cursor: pointer;
  }

  .personal_input {
    width: 85%;
    height: 50px;
    margin: 7px 20px 20px;
    font-size: 17px;
    padding-left: 10px;
    font-weight: 500;
    border-radius: 5px;
    border: solid 1px #d4d4d4;
    background-color: var(--white);
    display: flex;
    align-items: center;
  }

  .personal_input2 {
    width: 85%;
    height: 50px;
    margin: 7px 20px 20px;
    font-size: 17px;
    padding-left: 10px;
    font-weight: 500;
    border-radius: 5px;
    border: solid 1px #d4d4d4;
    background-color: var(--white);
    display: flex;
    align-items: center;
  }

  .personal_input2 input {
    border: none;
    outline: none;
    margin: unset;
    padding: unset;
    font-family: "Poppins";
    width: 96%;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
  }

  .genderDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 40px 20px 20px;
  }

  .personal_input input {
    border: none;
    outline: none;
    margin: unset;
    padding: unset;
    font-family: "Poppins";
    width: 90%;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
  }

  input[type="date"]::-webkit-inner-spin-button {
    color: grey;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    color: grey;
  }

  .personal_input input:focus {
    outline: none;
  }

  .Recommendation_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 35px;
    overflow: hidden;
    top: 0;
    z-index: 1;
    width: 100%;
    padding: 10px;
    padding-right: 20px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.14);
    background-color: #ffffff;
    position: fixed;
    top: 0;
    z-index: 2;
    width: 100%;
  }

  /*OTP CSS*/
  .otptitle {
    margin: 20px;
    margin-top: 10vh;
    object-fit: contain;
    font-size: 21px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }

  .otpsubtitle {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #747474;
    padding-left: 20px;
  }

  .otpsubtitle span {
    font-size: 18px;
  }

  .otpsubtitle label {
    font-size: 15px;
    padding-left: 10px;
    color: #e41e26;
  }

  .otpsubtitle2 {

    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.93;
    letter-spacing: normal;
    text-align: center;
    color: #8d8d8d;
  }

  .otpsubtitle2 label {
    font-size: 15px;
    padding-left: 10px;
    font-weight: 500;
    color: #e41e26;
  }

  .otp_input {
    width: 37px;
    height: 42px;
    margin-left: 15px;
    border-radius: 5px;
    border: 1px solid #eeeeee;
    background-color: #eeeeee;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.05;
    letter-spacing: normal;
    text-align: center;
    color: #e41e26;
  }

  .otp_input:focus {
    outline: none;
    background-color: #e41e251f;
    box-shadow: #e41e26;
  }

  @-webkit-keyframes otp_render_reverse {
    0% {
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
      transition-delay: 1s;
    }

    100% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
    }
  }

  @keyframes otp_render_reverse {
    0% {
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
      transition-delay: 1s;
    }

    100% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
    }
  }

  .otp_input_container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    -webkit-animation: ease-in otp_render_reverse 0.5s;
            animation: ease-in otp_render_reverse 0.5s;
  }

  .otp_input_container input:focus {
    outline: none;
    background-color: #e41e251f;
    box-shadow: #e41e26;
  }

  .otpotion {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.93;
    letter-spacing: normal;
    text-align: right;
    font-family: "Poppins";
    color: #e41e26;
    padding-left: 5px;
  }

  .head {
    color: blue;
  }

  .head {
    color: #e4e4e4;
    margin: unset;
    padding: unset;
    font-size: 35px;
    padding-left: 20px;
    color: red;
  }

  .Report_container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: white;
  }

  .Report_subcontainer {
    width: 100%;
  }

  h5 {
    padding: unset;
    margin: unset;
  }

  /* h4{
        padding: unset;
        margin: unset;
        margin-bottom: 15px;
    } */
  .other_motor_input {
    border-bottom: solid 1px #dddddd;
    border-top: unset;
    border-right: unset;
    border-left: unset;
    width: 90%;
    text-align: center;
    padding: 15px;
    font-size: 18px;
    margin-top: 20px;
    font-weight: 500;
    background: #fafafa;
  }

  .textAmount {
    text-align: center;
    width: 100%;
    padding: 10px;
    margin: unset;
    font-size: 13px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.42);
  }

  #namelabel {
    color: #8d8d8d;
    font-size: 15px;
  }

  #agelabel {
    color: #8d8d8d;
    font-size: 15px;
  }

  #citylabel {
    color: #8d8d8d;
    font-size: 15px;
  }

  .bullets {
    width: 4px;
    height: 4px;
    border-radius: 100;
    border-radius: 100px;
    margin-right: 5px;
    background-color: #e41e26;
  }

  .income_content {
    padding: 10px 1px 0 0;
    font-size: 12px;
    font-weight: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: rgba(0, 0, 0, 0.38);
  }

  .checkbox_input_city_container {
    margin: 5px 22px 0;
    padding: 0px 26px 0px 22px;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: white;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0, 1, 0, 1);
  }

  .checkbox_input_city_container.show {
    padding: 0px 26px 0px 22px;
    border-radius: 0px 0px 5px 5px;
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
    background-color: white;
    height: 300px;
    max-height: 9999px;
    overflow: scroll;
    transition: all 0.5s cubic-bezier(1, 0, 1, 0);
    margin: 20px;
    margin-top: -2px;
  }

  .checkbox_input_insurance_container label {
    border: 1px solid grey;
    padding: 5px;
    margin: 10px;
  }

  /* //Motor css */

  .existing_policy_title {
    margin: 0 39px 5px 1px;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }

  .existing_policy_subtitle {
    margin: 5px 56px 23px 2px;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: var(--warm-grey);
  }

  .existing_policy_option {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }

  .existing_policy_option_subtitle {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.7;
    letter-spacing: normal;
    text-align: left;
    color: var(--warm-grey);
  }

  .existing_policy_option_container {
    display: flex;
    align-items: center;
    /* border: 1px solid grey; */
    /* border-radius: 5px; */
    /* width: 335px; */
    /* height: 83px; */
    margin: 20px;
    padding: 5px;
    border-radius: 4px;
    border: solid 1px #d4d4d4;
    background-color: var(--white);
  }

  input {
    -webkit-writing-mode: horizontal-tb !important;
    text-rendering: auto;
    color: -internal-light-dark(green, white);
  }

  .two_wheeler_presentation {
    font-size: 3.5vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }

  .Bike_number_input {
    border: none;
    outline: none;
    border-bottom: 0.8px solid lightgrey;
    width: 90%;
    /* height: 23px; */
    margin: 30px 1.5px 20px 6.5px;
    object-fit: contain;
    font-size: 16px;
    padding: 5px;
  }

  .Two_Wheeler_btn {
    width: 90%;
    height: 55px;
    border-radius: 2px;
    background-color: #e41e26;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    border: unset;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }

  .Two_Wheeler_bikenumber_button {
    width: 90%;
    height: 55px;
    border-radius: 2px;
    color: #e41e26;
    font-size: 16px;
    margin: 13px 2px 12px 6px;
    border-radius: 5px;
    border: solid 1px #e41e26;
    background-color: var(--white);
  }

  .brands {
    width: auto;
    height: auto;
    margin: 5px;
  }

  .model_option_container {
    height: 45px;
    margin: 10px 20px 10px 10px;
    border-radius: 5px;
    border: solid 1px #d4d4d4;
    background-color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.83;
    letter-spacing: normal;
    text-align: left;
    color: #8d8d8d;
  }

  .btn_motor {
    width: 100%;
    height: 55px;
    border-radius: 2px;
    background-color: #e41e26;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    z-index: 1;
    border: unset;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }

  .motor_policy_input {
    width: 85%;
    height: 25px;
    font-size: 20px;
    font-weight: 500;
    margin: 0px 10px 10px 10px;
    outline: none;
    border: none;
    border-bottom: solid 2px #e41e26;
    background-color: transparent;
  }

  .motor_main_container {
    margin-top: 80px;
  }
}

.head_report {
  color: #e41e26;
  position: relative;
  top: -14px;
  left: 40px;
  background: white;
  padding: unset;
  margin: unset;
  padding-left: 10px;
  width: 310px;
}

.sub_head {
  padding-left: 44px;
  font-family: Roboto;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.49;
  letter-spacing: normal;
  text-align: left;
  color: #484848;
  padding: unset;
  margin: unset;
}

.outer_sec {
  background-color: white;

  margin: 20px;
  border: 1px solid grey;
}

.Report_subcontainer {
  border: 1px solid grey;
}

.info_sec {
  height: 60px;
  display: flex;
  justify-content: space-around;
  padding-top: 4%;
  padding-bottom: 4%;
  /* padding-left: 20px; */
  /* padding: 10px 20px 20px 20px; */
  padding-left: 20px;
  /* padding-right: 44px; */
  background-color: #eeeeee;
}

.insurance_head {
  font-family: Roboto;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.83;
  text-align: center;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.77);
  padding: unset;
  margin: unset;
  padding-bottom: 20px;
}

.other_info {
  font-size: 16px;
  padding: unset;
  margin: unset;
}

.other_info span {
  font-size: 9px;
}

.otherinfo_container {
  padding: 31px 18px 40px 40px;
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.08);
}

.Report_card {
  background: #fff4f4;
  right: 0;
  padding: 4px;
  padding-bottom: 4px;
  border-radius: 5px;
  margin: 10px 0px 0px;
}

.column-card-2_report {
  display: flex;
  padding: 12px 20px 2px 2px;
}

.recommendation-card-sv_report {
  z-index: 1;
  position: relative;
  width: 40px;
}

.recommend-card-title_report {
  margin-left: 10px;
  margin-bottom: 5px;
  /* font-family: SFProText; */
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: black;
}

.recommend-card-subtitle_report {
  margin-left: 10px;
  display: flex;
  align-items: center;
  /* font-family: SFProText; */
  color: #000000;
  opacity: 0.54;
  font-size: 10px;
}

.row-card-1_report {
  margin: 5px 0px;
  margin-bottom: 3px;
  display: flex;
  justify-content: space-between;
}

.cover-text_report {
  opacity: 0.44;
  /* font-family: SFProText; */
  font-size: 8px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  padding: unset;
  margin: unset;
}

.recommend-card-amt_report {
  margin: 0 0px 0px 15px;
  /* font-family: SFProText; */
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #e41e26;
}

.recommendation-card-svg_report {
  z-index: 1;
  position: relative;
  width: 30px;
}

.btn_for_account_switch {
  width: 100%;
  height: 45px;
  border-radius: 2px;
  background-color: #e41e26;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  bottom: 0;
  font-family: "Poppins";
  border: unset;
  border-radius: 5px;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.bullets_report {
  width: 4px;
  height: 4px;
  border-radius: 100;
  border-radius: 100px;
  margin-right: 5px;
  background-color: #e41e26;
}

.steps {
  display: flex;
  align-items: center;
  height: 50px;
}

.steps h4 {
  font-size: 11px;
}

.recommend-card-amt-black_report {
  margin: 0 0px 0px 15px;
  /* font-family: SFProText; */
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: right;
  padding-right: 10px;
  color: #64a819;
}

.imp_disclaimer {
  color: lightgrey;
  font-size: 15px;
  line-height: 1.6;
  color: #757575;
  opacity: 0.8;
}

.key_features_container {
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  margin-bottom: 5px;
  padding: 1px 0 4px 10px;
  background-color: #fff8f8;
}

.key_features_container p {
  font-weight: 600;
  font-size: 17px;
}

.key_features_container ul li {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
}

ul li {
  list-style: none;
}

.slider-control-bottomcenter ul li::before {
  display: none;
}
 

ul li::before {
  content: "\2022";
  color: red;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.mandatory_warning {
  float: right;
  color: red;
  font-weight: 400;
  font-size: 12px;
  padding: 5px 30px 15px 5px;
}

.home_cover_input {
  background-color: white;
  box-shadow: 0 3px 6px 0 rgb(172 172 172 / 34%);
}

.existing_content_cover {
  margin: unset;
  padding: 10px 10px 10px 10px;
  font-size: 17px;
  font-weight: 500;
  color: red;
}

.sub_recommended_cover {
  color: forestgreen;
  font-size: 11px;
}


:root {
  --animation-color: white;
}

.loader {
  width: inherit;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: inherit;

}

.sub_loader {
  display: flex;
  justify-content: space-evenly;
  width: 38px;
}

.circle_loader {
  display: inline-block;
  width: 9px;
  height: 9px;
  border-radius: 100%;
  color: white;
  color: var(--animation-color);
  vertical-align: middle;
}

.circle_loader:nth-child(1) {
  background-color: white;
  background-color: var(--animation-color);
  -webkit-transform: translate(-5px);
          transform: translate(-5px);
  opacity: 0;
  -webkit-animation: animateLoader1 0.5s linear infinite;
          animation: animateLoader1 0.5s linear infinite;
}

.circle_loader:nth-child(2) {
  background-color: white;
  background-color: var(--animation-color);
  -webkit-animation: animateLoader-middle 0.5s linear infinite;
          animation: animateLoader-middle 0.5s linear infinite;
}

.circle_loader:nth-child(3) {
  background-color: white;
  background-color: var(--animation-color);
  -webkit-animation: animateLoader-middle 0.5s linear infinite;
          animation: animateLoader-middle 0.5s linear infinite;
}

.circle_loader:nth-child(4) {
  background-color: white;
  background-color: var(--animation-color);
  -webkit-animation: animateLoader2 0.5s linear infinite;
          animation: animateLoader2 0.5s linear infinite;
}

@-webkit-keyframes animateLoader1 {
  to {
    -webkit-transform: translate(5px);
    transform: translate(5px);
    opacity: 1;
  }
}

@keyframes animateLoader1 {
  to {
    -webkit-transform: translate(5px);
    transform: translate(5px);
    opacity: 1;
  }
}

@-webkit-keyframes animateLoader2 {
  to {
    -webkit-transform: translate(10px);
    transform: translate(10px);
    opacity: 0;
  }
}

@keyframes animateLoader2 {
  to {
    -webkit-transform: translate(10px);
    transform: translate(10px);
    opacity: 0;
  }
}

@-webkit-keyframes animateLoader-middle {
  to {
    -webkit-transform: translate(5px);
    transform: translate(5px);
    opacity: 1;
  }
}

@keyframes animateLoader-middle {
  to {
    -webkit-transform: translate(5px);
    transform: translate(5px);
    opacity: 1;
  }
}

.feedback_container {
  padding: 20px 40px;
}

.feedback_container .feedback_subcontainer {
  display: flex;
  justify-content: space-between;
}

@-webkit-keyframes enable_feedback {
  from {
    width: 20%;
    -webkit-transform: scale(0) translateY(0);
  }

  to {
    width: 90%;
    -webkit-transform: scale(1) translateY(-30px)
  }
}

@keyframes enable_feedback {
  from {
    width: 20%;
    -webkit-transform: scale(0) translateY(0);
  }

  to {
    width: 90%;
    -webkit-transform: scale(1) translateY(-30px)
  }
}

.geeks_active {
  width: 90%;
  height: 160px;
  background: white;
  position: fixed;
  bottom: 40px;
  box-shadow: 0px 2px 30px 0px;
  /* opacity: 1;
    scale: 1; */
  transition: .5s;
  /* box-shadow: 0px 5px 80px 0px; */
  background: white;
  border-radius: 0.5em;
  -webkit-transform: none;
          transform: none;
}

.geeks {
  scale: .2;
  transition: .5s;
  opacity: .1;
  -webkit-filter: blur(3px);
          filter: blur(3px);
  width: 30%;
  -webkit-transform: translateY(200px);
          transform: translateY(200px);
}


/* .swiper {
  width: 100%;
  height: 100%;
} */

/* .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff; */

/* Center slide text vertically */
/* display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
} */

.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.swiper-container {
  height: 100vh
}

.swiper-container-horizontal>.swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 25% !important;
  left: 0;
  width: 100%;
}

/* .swiper-pagination {
  margin-top: 90vh !important;
} */

.carousal_header {
  /* font-family: DM Sans; */
  font-size: 24px;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: -0.800000011920929px;
  text-align: center;
  margin: 30px 0 10px 0;
  color: #2D2B2E;
}

.carousal_content {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.30000001192092896px;
  text-align: center;
  padding: 0 40px;
  color: #2D2B2E;
}


.kom_btn:active {
  background-color: #e22e4b;
}

.kom_start_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 24px;
  grid-gap: 8px;
  gap: 8px;

  width: 100%;
  height: 44px;
  background: #E41C3C;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */
  text-align: center;
  color: #FFFFFF;
  outline: none;
  border: none;
}

.mt-20 {
  margin-top: 20vh;
}

.mt-15 {
  margin-top: 15vh;
}

.kom_skip_btn {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px;
  grid-gap: 10px;
  gap: 10px;

  position: absolute;
  width: 55px;
  height: 42px;
  right: 20px;
  top: 20px;

  background: #FFFFFF;
  opacity: 0.8;
  border-radius: 6px;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;

  color: #2D2B2E;
  outline: none;
  border: none;
}


.slider-control-bottomcenter {
  /* margin-top: 100px;
  height: 100p !important; */

  /* padding-bottom: 30vh; */
  padding-bottom: 20vh;
}


.slide-current {
  height: 100vh !important;
}

.slide-visible {
  height: 100vh !important;
}

 /* apply color to key features bullet ponit */

 

.slider-control-centerleft,
.slider-control-centerright {
  display: none;
}

.filler_screen_Header {
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  color: #3A3A3A;
  margin-top: 10px
}

.filler_screen_content {
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #666666;
  margin: 25px 0 50px 0
}

.la_familia_skip_btn {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  color: #2D2B2E;
}

.brand_icons_header {
  font-style: italic;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #3A3A3A;
  margin-bottom: 10px;
}

.brand_icons {
  display: flex;
  justify-content: center;
  align-items: center;
}
